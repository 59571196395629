@import url('https://rsms.me/inter/inter.css');

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  background-color: rgb(0, 0, 0);
}

#root {
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
}

.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trails-text {
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 110px;
  color: rgb(255, 255, 255);
  font-size: 14vmin; 
  font-weight: 750;
  letter-spacing: -4px;
  will-change: transform, opacity;
  overflow: hidden;
}

.trails-text > div {
  overflow: hidden;
}
